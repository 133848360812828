import React from 'react';
import { Box, pxRem } from '@sanch941/lib';
import { navigationMenuList } from '@lib';
import { useLocation } from '@reach/router';
import { Container } from '../container';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import styled, { css } from 'styled-components';

import logo from '@assets/images/logo.svg';
import { Link } from 'gatsby';

export const Desktop = () => {
    const { pathname } = useLocation();

    return (
        <Container>
            <StyledContainer $flex ai="center" jc="space-between">
                <a href="/">
                    <StyledLogo src={logo} alt="logo" />
                </a>

                <Box $flex ai="center">
                    {navigationMenuList.map(({ text, to, ...props }, idx) => (
                        <StyledLink
                            as={AnchorLink}
                            $active={pathname === to}
                            to={to}
                            key={idx}
                            {...props}
                        >
                            {text}
                        </StyledLink>
                    ))}
                    <StyledLink
                        as="a"
                        href="https://developer.maxpay.kz/"
                        target="_blank"
                    >
                        Разработчикам
                    </StyledLink>
                    {/* <StyledLink as="a" href="tel:+7 771 218 50 08">
                        +7 771 218 50 08
                    </StyledLink> */}
                </Box>
            </StyledContainer>
        </Container>
    );
};

const StyledContainer = styled(Box)`
    padding: ${pxRem(16)} ${pxRem(32)};
    box-shadow: 0px 8.05706px 8.71033px rgba(22, 25, 46, 0.1);
    border-radius: 4px;
    background-color: #fff;
`;

const StyledLogo = styled.img`
    max-height: ${pxRem(100)};
`;

const StyledLink = styled.a<{ $active?: boolean }>`
    font-weight: 400;
    font-size: ${pxRem(16)};
    line-height: ${pxRem(23)};
    color: #000;
    position: relative;
    transition: color 0.3s ease-in-out;

    &:not(:first-child) {
        margin-left: ${pxRem(32)};
    }

    &:hover {
        color: #7c2cea;
    }
`;

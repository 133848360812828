import React from 'react';
import { Container } from '@ui';
import { Box, pxRem, Text } from '@sanch941/lib';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import card from '@assets/images/advantages/card.svg';
import heart from '@assets/images/advantages/heart.svg';
import lock from '@assets/images/advantages/lock.svg';

import styled from 'styled-components';

export const AdvantagesTemplate = () => {
    return (
        <Box bg="#1C1B1B" id="advantages">
            <Container>
                <Box pTop={32} pBottom={32} md={{ pTop: 32, pBottom: 96 }}>
                    <AnimationOnScroll
                        animateIn="animate__fadeInUp"
                        animateOnce={true}
                    >
                        <Text
                            as="h2"
                            $color="#fff"
                            ta="center"
                            tt="uppercase"
                            fw={400}
                            fz={18}
                            md={{ fz: 24, lh: '132%' }}
                        >
                            MaxPay 🔥 — это:
                        </Text>
                    </AnimationOnScroll>

                    <Box md={{ $flex: true, jc: 'space-between' }}>
                        <AnimationOnScroll
                            animateIn="animate__fadeInUp"
                            animateOnce={true}
                            delay={300}
                        >
                            <Box
                                $flex
                                fxd="column"
                                ai="center"
                                $bottom={48}
                                pTop={170}
                                $top="-50px"
                                md={{ $bottom: 0 }}
                            >
                                <StyledImg src={card} />

                                <Box $bottom={8}>
                                    <Text
                                        $color="#fff"
                                        fz={19}
                                        lh="132%"
                                        fw={500}
                                    >
                                        Быстро и удобно
                                    </Text>
                                </Box>
                                <StyledText>
                                    Мы быстро обрабатываем платежи и предлагаем
                                    разные методы оплаты для улучшения
                                    производительности и снижения издержек
                                    бизнес-клиентов.
                                </StyledText>
                            </Box>
                        </AnimationOnScroll>
                        <AnimationOnScroll
                            animateIn="animate__fadeInUp"
                            animateOnce={true}
                            delay={500}
                        >
                            <Box
                                $flex
                                fxd="column"
                                ai="center"
                                $bottom={48}
                                pTop={170}
                                $top="-50px"
                                md={{ $bottom: 0 }}
                            >
                                <StyledImg
                                    src={heart}
                                    style={{ top: '-20px' }}
                                />

                                <Box $bottom={8}>
                                    <Text
                                        $color="#fff"
                                        fz={19}
                                        lh="132%"
                                        fw={500}
                                    >
                                        Адаптивно
                                    </Text>
                                </Box>
                                <StyledText>
                                    Мы предлагаем индивидуальные бизнес-решения,
                                    опираясь на опыт работы с компаниями и
                                    стремимся к долгосрочным партнерским
                                    отношениям.
                                </StyledText>
                            </Box>
                        </AnimationOnScroll>
                        <AnimationOnScroll
                            animateIn="animate__fadeInUp"
                            animateOnce={true}
                            delay={800}
                        >
                            <Box
                                $flex
                                fxd="column"
                                ai="center"
                                $bottom={48}
                                pTop={170}
                                $top="-50px"
                                md={{ $bottom: 0 }}
                            >
                                <StyledImg src={lock} style={{ top: '31px' }} />

                                <Box $bottom={8}>
                                    <Text
                                        $color="#fff"
                                        fz={19}
                                        lh="132%"
                                        fw={500}
                                    >
                                        Безопасно
                                    </Text>
                                </Box>
                                <StyledText>
                                    Мы обеспечиваем надежную защиту платежей и
                                    данных клиентов, используя сертифицированный
                                    стандарт PCI DSS Level 1, 3D Secure и
                                    шифрование данных.
                                </StyledText>
                            </Box>
                        </AnimationOnScroll>
                    </Box>
                </Box>
            </Container>
        </Box>
    );
};

const StyledImg = styled.img`
    margin-bottom: 8px;
    position: absolute;
    top: 0;
`;

const StyledText = styled(Text)`
    max-width: ${pxRem(343)};
    font-weight: 400;
    font-size: 15px;
    line-height: 132%;
    text-align: center;
    color: #bfbfbf;
`;

import { pxRem } from '@lib';
import { media } from '@sanch941/lib';
import styled from 'styled-components';

export const SectionTitle = styled.h2`
    font-weight: 700;
    font-size: ${pxRem(32)};
    line-height: ${pxRem(38)};
    color: #470bbe;
    text-align: center;

    ${media.md} {
        font-size: ${pxRem(36)};
        line-height: ${pxRem(42)};
    }
`;

import { IsDesktop, IsMobile, media, pxRem } from '@sanch941/lib';
import React from 'react';
import styled from 'styled-components';
import { Desktop } from './desktop';
import { Mobile } from './mobile';

export const Header = () => {
    return (
        <StyledContainer>
            <IsTablet>
                <Mobile />
            </IsTablet>
            <IsDesktopMy>
                <Desktop />
            </IsDesktopMy>
        </StyledContainer>
    );
};

const StyledContainer = styled.div`
    position: fixed;
    top: ${pxRem(16)};
    z-index: 1;
    width: 100%;

    ${media.md} {
        top: ${pxRem(24)};
    }
`;

export const IsTablet = styled.div`
    ${media.lg} {
        display: none;
    }
`;

export const IsDesktopMy = styled.div<{ $display?: string }>`
    display: none;

    ${media.lg} {
        display: ${({ $display = 'block' }) => $display};
    }
`;

import React from 'react';
import { AdvantagesTemplate } from './templates/advantages/advantages';
import { HowItWorksTemplate } from './templates/how-it-works/how-it-works';
import { WelcomeTemplate } from './templates/welcome/welcome';
import { QuestionsTemplate } from './templates/questions/questions';
import { CommonTemplate } from '@ui';
import { PciDssTemplate } from './templates/pci-dss/pci-dss';

export const HomePage = () => {
    return (
        <CommonTemplate>
            <WelcomeTemplate />
            <AdvantagesTemplate />
            <HowItWorksTemplate />
            <PciDssTemplate />
            <QuestionsTemplate />
        </CommonTemplate>
    );
};

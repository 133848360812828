import React, { FC } from 'react';
import { media, pxRem } from '@sanch941/lib';
import { keyframes } from 'styled-components';
import styled, { css } from 'styled-components';

const gradient = keyframes`
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
`;

export const Button: FC<ComponentProps> = ({ children, variant, ...props }) => {
    return (
        <StyledButton variant={variant} {...props}>
            {children}
        </StyledButton>
    );
};

type Variant = 'outline' | 'gradient';

const getStylesByVariant = (variant: Variant) => {
    switch (variant) {
        case 'outline':
            return css`
                border: 2px solid #470bbe;
                color: #470bbe;
            `;
        case 'gradient':
            return css`
                color: white;
                box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.25);
                background-color: none;
                background: linear-gradient(
                    90.5deg,
                    #470bbe 0%,
                    #a578ff 48.96%,
                    #470bbe 100%
                );
                background-size: 400% 400%;
                animation: ${gradient} 3s linear infinite;
            `;
        default:
            return css`
                background-color: #470bbe;
                color: white;

                &:disabled {
                    background-color: #c4c4c4;
                }
            `;
    }
};

interface ComponentProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    variant?: Variant;
}

interface StyledButtonProps {
    variant: Variant;
}

const StyledButton = styled.button<StyledButtonProps>`
    border: none;
    font-size: ${pxRem(18)};
    font-weight: 700;
    border-radius: 10px;
    width: 100%;
    height: ${pxRem(60)};
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    ${({ variant }) => getStylesByVariant(variant)};

    ${media.md} {
        width: ${pxRem(185)};
        height: ${pxRem(60)};
    }
`;

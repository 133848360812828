export interface FaqItem {
    id: number;
    title: string;
    description: string;
}

export const faqList: FaqItem[] = [
    {
        id: 1,
        title: 'Что такое платежное решение?',
        description:
            'Платежное решение — это технологический продукт, который позволяет вашему онлайн-бизнесу принимать платежи от клиентов. Чтобы лучше разобраться в платежных решениях, давайте изучим основы процессинга онлайн-платежей'
    },
    {
        id: 2,
        title: 'Знакомство с миром платежей и типами платежных решении',
        description: `В мире онлайн-платежей весь процесс оплаты кажется простым, но на самом деле это сложная система, в которой участвуют множество сторон. Для каждого метода оплаты список участников будет отличаться. Например, вот участники процессинга карточной онлайн-транзакции:
            <ul>
            <li>Клиент — это потребитель, который инициирует покупку на вашем веб-сайте, в мобильном приложении и т.д. Он является держателем карты.</li>
            <li>Мерчант — это вы, онлайн-бизнес.</li>
            <li>Эквайер — банк, в котором у вас есть мерчант аккаунт, позволяющий принимать карточные платежи</li>
            <li>Платежный шлюз — это программное обеспечение, которое передает данные транзакции между вашим сайтом и финансовым учреждением-эквайером. Каждая транзакция должна пройти через шлюз, прежде чем процесс оплаты продолжится.</li>
            <li>Платежный процессор — посредник между держателем карты, продавцом и финансовыми учреждениями, который управляет процессингом транзакций по карте: авторизует транзакции, обеспечивает перемещение средств и т.д. </br> 
            Платежная сеть, или ассоциация кредитных карт, представляет собой группу банков или учреждений, выпускающих карты. Они устанавливают единые условия транзакций для продавцов, эмитентов и эквайеров. Наиболее известными из них являются глобальные бренды, такие как Mastercard, Visa, American Express, UnionPay и JCB.
            </li>
            </ul>
            Среди всех этих сторон термин «платежное решение» в основном применяется к шлюзам и другим провайдерам платежных услуг (PSP) и может относиться как к компании, так и к ее продуктам.`
    },
    {
        id: 3,
        title: 'Как понять, нуждается ли ваш бизнес в платежном решении?',
        description:
            'Во-первых, без него вы не сможете принимать деньги от клиентов в Интернете. Но на самом деле, выбор платежной системы влияет на то, насколько клиентам будет легко и удобно совершать покупки на вашем сайте, а также на то, насколько безопасны будут их данные. Это может оказаться важным для сохранения репутации вашего бизнеса.'
    },
    {
        id: 4,
        title: 'Основные характеристики высококачественного агрегатора платежей',
        description: `Если вы ведете бизнес и хотите расширить свою деятельность и увеличить доходы, то мы рекомендуем интегрировать платежный шлюз, который:
            <ul>
            <li>Безопасен. Безопасность важна для платежей, поэтому компаниям нужно использовать многоуровневую защиту, включая токенизацию, шифрование и защиту от мошенничества, чтобы избежать утечек данных клиентов и сохранить свою репутацию. Также важно принимать меры по предотвращению мошенничества и использовать только безопасные методы онлайн-платежей, чтобы избежать потерь.</li>
            <li>Инновационен. Хороший платежный шлюз должен быть готов к изменениям в финансовом мире и предлагать новые методы электронных платежей, расширенные возможности и функции для эффективного управления платежами и их обработки.</li>
            <li>Надежен. Хороший платежный шлюз должен предоставлять надежный сервис и находить индивидуальный подход к каждому клиенту.</li>
            </ul>`
    },
    {
        id: 5,
        title: 'Что такое система выплат?',
        description:
            'Выплаты - это неотъемлемая часть работы любого онлайн-бизнеса. Это процесс перевода денежных средств на счета партнеров, подрядчиков, сотрудников и других получателей по всему миру. Для эффективной работы с выплатами необходима система, которая обеспечивает безопасность и простоту проведения платежей. Система выплат позволяет быстро и удобно осуществлять исходящие транзакции в любой валюте и на любой счет.'
    },
    {
        id: 8,
        title: 'Что такое сверка балансов?',
        description:
            'Сверка балансов - это процесс проверки и согласования финансовых данных. Данные о транзакциях на ваших счетах сравниваются с данными провайдеров, чтобы убедиться, что все ожидаемые платежи были успешно обработаны. </br> Для лучшего понимания сверки балансов, можно представить себе две бухгалтерские книги с данными о транзакциях и платежах провайдера. Цель сверки балансов - выявить любые расхождения или убедиться в точности данных по балансам. </br> MaxPay позволяет выполнять сверку балансов автоматически или вручную через наш API. Частота проведения процедуры зависит от размера и типа вашего бизнеса, и чем больше транзакций проходит ежедневно, тем чаще следует проводить сверку балансов.'
    },
    {
        id: 9,
        title: 'Виды сверки',
        description:
            'Сверка обычно делится на две категории — ручная и автоматическая. Оба типа широко используются, но предназначены для организаций разных типов и размеров. Чем больше организация, тем лучше выполнять автоматическую сверку вместо ручной. </br> Также, существует следующие типы финансовой сверки: сверка по провайдерам, банковская сверка, сверка по клиентам и внутренняя сверка по компании.'
    },
    {
        id: 10,
        title: 'Что такое рекуррентные платежи?',
        description:
            'Рекуррентные платежи — это автоматические повторяющиеся платежи за товары или услуги, которые вы заказали. Например, если вы подписались на ежемесячную доставку продуктов, рекуррентный платеж будет автоматически списываться с вашей карты каждый месяц. Это удобный способ оплаты без необходимости каждый раз вводить данные карты.'
    }
];

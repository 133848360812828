import React, { FC } from 'react';
import { Footer } from './footer';

import 'swiper/css';
import { Header } from './header';
import { Initialization } from '@features/initialization/initialization';

export const CommonTemplate: FC = ({ children }) => {
    return (
        <Initialization>
            <Header />

            {children}

            <Footer />
        </Initialization>
    );
};

import React from 'react';
import { Box, pxRem, Text } from '@sanch941/lib';
import styled, { css } from 'styled-components';

interface ComponentProps {
    autoComplete?: string;
    disabled?: boolean;
    error?: string;
    label?: string;
    name: string;
    onBlur?: (e: any) => void;
    onChange?: (e: any) => void;
    placeholder?: string;
    type: string;
    value?: string;
    required?: boolean;
    as?: string | React.ComponentType<any>;
    rows?: number;
}

export const Input = ({
    autoComplete,
    disabled,
    error,
    label,
    name,
    onBlur,
    onChange,
    placeholder,
    type,
    value,
    ...props
}: ComponentProps) => (
    <Box>
        {label && <InputLabel>{label}</InputLabel>}
        <InputNative
            autoComplete={autoComplete}
            disabled={disabled}
            error={Boolean(error)}
            name={name}
            onBlur={onBlur}
            onChange={onChange}
            placeholder={placeholder}
            type={type}
            value={value}
            {...props}
        />
    </Box>
);

const InputNative = styled.input<{ error: boolean }>`
    border: 1px solid #c2c2c2;
    border-radius: 8px;
    position: relative;
    padding: ${pxRem(8)} ${pxRem(16)};
    font-size: ${pxRem(16)};
    line-height: ${pxRem(24)};
    width: 100%;
    box-sizing: border-box;
    outline: none;
    box-shadow: inset 1px 2px 2px rgba(0, 0, 0, 0.08);
    background-color: white;
    color: #9e9e9e;
    ${(p) =>
        p.error &&
        css`
            box-shadow: 0 0 0 1px red;
            border-color: red;
        `}
`;

const InputLabel = styled(Text).attrs({
    fz: 16,
    lh: 24,
    $color: '#404040',
    as: 'label'
})`
    display: block;
    margin-bottom: ${pxRem(4)};
`;

import React, { FC } from 'react';
import { Provider } from 'react-redux';
import { store } from '@store';
import { ErrorBoundary } from '@features/error-boundary';
import { GlobalStyles } from './global-styles';
import 'animate.css/animate.min.css';

export const App: FC = ({ children }) => {
    return (
        <Provider store={store}>
            <ErrorBoundary>
                <GlobalStyles />

                {children}
            </ErrorBoundary>
        </Provider>
    );
};

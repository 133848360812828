import React from 'react';
import { Container } from './container';
import { Box, media, pxRem, Text } from '@sanch941/lib';
import styled from 'styled-components';
import logo from '@assets/images/logo_white.svg';
import pcidss from '@assets/images/pci-dss.svg';

import visa from '@assets/images/visa.png';
import mastercrd from '@assets/images/mastercard.png';
import union from '@assets/images/union.png';
import american from '@assets/images/american.png';
import phone2 from '@assets/images/footer/smartphone.svg';
import email from '@assets/images/footer/email.svg';
import pdf from '@assets/images/footer/PDF.svg';

import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { Button } from '@ui/atoms/button';

export const Footer = () => {
    return (
        <StyledContainer>
            <Container>
                <Box md={{ $flex: true, jc: 'space-between' }}>
                    <Box>
                        <StyledLogo src={logo} />
                        <Box $top={10} md={{ $top: 19 }}>
                            <StyledLogo src={pcidss} />
                        </Box>
                        <Box md={{ $flex: true, ai: 'center' }}>
                            <Box $top={10} md={{ $top: 19, $right: 20 }}>
                                <StyledLogo src={visa} height={40} />
                            </Box>
                            <Box $top={10} md={{ $top: 19, $right: 20 }}>
                                <StyledLogo src={mastercrd} height={40} />
                            </Box>
                        </Box>
                        <Box
                            $top={10}
                            md={{ $flex: true, ai: 'center', $top: 19 }}
                        >
                            <Box $top={10} md={{ $top: 19 }}>
                                <StyledLogo src={union} />
                            </Box>
                            <Box $top={10} md={{ $top: 19 }}>
                                <StyledLogo src={american} />
                            </Box>
                        </Box>
                    </Box>
                    <Box $top={16} md={{ $top: 0 }}>
                        <Text
                            fz={18}
                            lh={23}
                            $color="#fff"
                            fw={400}
                            tt="uppercase"
                            ta="center"
                            md={{ fz: 24, lh: '132%' }}
                        >
                            Давайте обсудим Ваш проект ☕{' '}
                        </Text>
                        <Box $flex fxd="column" ai="center">
                            <Box $flex $top={12} ai="center">
                                <StyledIcon src={phone2} alt="phone" />
                                <StyledLink as="a" href="tel: +7 771 218 50 08">
                                    +7 727 310 24 02
                                </StyledLink>
                            </Box>
                            <Box $flex $top={12} ai="center">
                                <StyledIcon src={email} alt="email" />
                                <StyledLink
                                    as="a"
                                    href="mailto: info@maxpay.kz"
                                >
                                    hello@maxpay.kz
                                </StyledLink>
                            </Box>
                            <Box $top={24} $flex jc="center">
                                <Box $width={200} md={{ $width: 246 }}>
                                    <StyledButton
                                        as={AnchorLink}
                                        to="/#connection"
                                    >
                                        Связаться с нами
                                    </StyledButton>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box $top={40} md={{ $top: 0 }}>
                        <Box
                            $flex
                            ai="center"
                            jc="center"
                            md={{ jc: 'flex-end' }}
                        >
                            <img src={pdf} alt="pdf" />
                            <Box $left={8}>
                                <Text
                                    fw={400}
                                    $color="#E9E9E9"
                                    fz={16}
                                    lh={20}
                                    md={{ fz: 18 }}
                                >
                                    Юридические документы:
                                </Text>
                            </Box>
                        </Box>
                        <Box $top={12}>
                            <StyledText
                                as="a"
                                target="_blank"
                                href="/Политика конфиденциальности.pdf"
                            >
                                Политика конфиденциальности
                            </StyledText>
                            <StyledText
                                as="a"
                                target="_blank"
                                href="/Публичный Договор.pdf"
                            >
                                Публичный договор
                            </StyledText>
                            <StyledText
                                as="a"
                                target="_blank"
                                href="/Правила ПО.pdf"
                            >
                                Правила ПО
                            </StyledText>
                            <StyledText
                                as="a"
                                target="_blank"
                                href="/Анкета.pdf"
                            >
                                Анкета на присоединение
                            </StyledText>
                            <StyledText
                                as="a"
                                target="_blank"
                                href="/Оферта для физических лиц.pdf"
                            >
                                Оферта плательщикам
                            </StyledText>
                            <StyledText
                                as="a"
                                target="_blank"
                                href="/Вниманию_пользователей.pdf"
                            >
                                Внимание! Пользователям!
                            </StyledText>
                        </Box>
                    </Box>
                    {/* <StyledLink to="/#how-it-work">
                        Как это работает?
                    </StyledLink>
                    <StyledLink to="/#faq">Вопросы и ответы</StyledLink>
                    {/* <StyledLink to="/#tariffs">Наши тарифы</StyledLink> */}
                </Box>
                <Box $top={32}>
                    <Box>
                        <Text
                            $color="#E9E9E9"
                            fz={14}
                            fw={300}
                            lh={20}
                            ta="center"
                        >
                            Учетная регистрация Национального банка Республики{' '}
                            <br />
                            Казахстан №02-21-103 от 09.09.2021
                        </Text>
                    </Box>
                    <Box $top={30}>
                        <Text
                            $color="#E9E9E9"
                            fz={14}
                            fw={500}
                            lh={20}
                            ta="center"
                        >
                            050021, Казахстан, г. Алматы, пр. Достык 97, <br />{' '}
                            офис 8
                        </Text>
                    </Box>
                    <Box $top={30}>
                        <Text
                            $color="#626262"
                            fz={15}
                            fw={400}
                            lh={28}
                            ta="center"
                        >
                            Copyright 2023 maxpay.kz Все права защищены
                        </Text>
                    </Box>
                </Box>
            </Container>
        </StyledContainer>
    );
};

const StyledContainer = styled.div`
    padding: ${pxRem(32)} 0;
    background-color: #151517;
`;

const StyledLogo = styled.img`
    max-height: ${pxRem(58)};
    margin: 0 auto;

    ${media.md} {
        margin: 0;
    }
`;

const StyledIcon = styled.img`
    max-height: ${pxRem(24)};
    max-width: ${pxRem(24)};

    ${media.md} {
        max-height: ${pxRem(32)};
    }
`;

const StyledLink = styled(AnchorLink)`
    display: block;
    text-align: center;
    font-weight: 500;
    margin-left: ${pxRem(8)};
    font-size: ${pxRem(12)};
    line-height: 132%;
    color: #ffffff;

    ${media.md} {
        display: inherit;
        text-align: left;
        margin-bottom: 0;
        font-size: ${pxRem(24)};
    }
`;

const StyledText = styled.a`
    display: block;
    text-align: center;
    margin-top: 8px;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    text-decoration-line: underline;
    color: #e9e9e9;

    ${media.md} {
        font-size: ${pxRem(16)};
        text-align: right;
    }
`;

const StyledButton = styled(Button)`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: ${pxRem(40)};
    background-color: #7d2dea;
    border-radius: 4px;
    font-weight: 500;
    font-size: ${pxRem(18)};
    line-height: ${pxRem(20)};
    letter-spacing: 0.04em;
    color: #ffffff;
`;

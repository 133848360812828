import React from 'react';
import { Container } from '@ui';
import { Box, Text, pxRem, media } from '@sanch941/lib';
import { AnimationOnScroll } from 'react-animation-on-scroll';

import adv1 from '@assets/images/how-it-works/1.png';
import adv2 from '@assets/images/how-it-works/2.png';
import adv5 from '@assets/images/how-it-works/3.png';
import adv4 from '@assets/images/how-it-works/4.png';
import adv3 from '@assets/images/how-it-works/5.png';
import adv6 from '@assets/images/how-it-works/6.png';

import line from '@assets/images/line.svg';
import visa from '@assets/images/how-it-works/visa.svg';
import styled from 'styled-components';

export const HowItWorksTemplate = () => {
    return (
        <Box
            pTop={50}
            pBottom={40}
            md={{ pTop: 32, pBottom: 88 }}
            id="services"
        >
            <Container>
                <AnimationOnScroll
                    animateIn="animate__fadeInUp"
                    animateOnce={true}
                >
                    <StyledTitle
                        fw={400}
                        fz={18}
                        lh={35}
                        ta="center"
                        tt="uppercase"
                        $color="#000"
                        md={{ fz: 24, lh: '132%' }}
                    >
                        Наши услуги 💜
                    </StyledTitle>
                </AnimationOnScroll>

                <Box $top={16}>
                    <AnimationOnScroll
                        animateIn="animate__fadeInUp"
                        animateOnce={true}
                    >
                        <StyledTitle
                            fw={400}
                            fz={16}
                            lh={20}
                            ta="center"
                            $color="#6B6B6B"
                            md={{ fz: 18, lh: 24 }}
                        >
                            Мы предоставляем интернет-эквайринг, быстрые выплаты
                            на банковские карты и моментальные онлайн-переводы
                            через электронный кошелек и мобильную коммерцию.
                        </StyledTitle>
                    </AnimationOnScroll>
                </Box>

                <Box $top={40}>
                    <AnimationOnScroll
                        animateIn="animate__fadeInUp"
                        animateOnce={true}
                    >
                        <StyledTitle
                            fw={400}
                            fz={18}
                            lh={35}
                            ta="center"
                            tt="uppercase"
                            $color="#000"
                            md={{ fz: 24, lh: '132%' }}
                        >
                            ПО СЛЕДУЮЩИМ ТАРИФАМ 🧐
                        </StyledTitle>
                    </AnimationOnScroll>
                </Box>
                <Box $top={8}>
                    <Text
                        fw={400}
                        fz={16}
                        lh={20}
                        ta="center"
                        $color="#6B6B6B"
                        md={{ fz: 18, lh: 24 }}
                    >
                        Минимальная комиссия на
                    </Text>
                </Box>
                <Box $top={16} $flex jc="center" ai="center">
                    <Box>
                        <Text
                            fw={400}
                            fz={16}
                            lh={20}
                            ta="center"
                            $color="#000"
                            md={{ fz: 18, lh: 24 }}
                        >
                            Прием
                        </Text>
                        <Box>
                            <StyledPeercent>3,5%</StyledPeercent>
                        </Box>
                    </Box>
                    <Box $left={10} $right={10} md={{ $left: 40, $right: 40 }}>
                        <img src={line} alt="line" />
                    </Box>
                    <Box>
                        <Text
                            fw={400}
                            fz={16}
                            lh={20}
                            ta="center"
                            $color="#000"
                            md={{ fz: 18, lh: 24 }}
                        >
                            Выплату
                        </Text>
                        <Box>
                            <StyledPeercent>1,5%</StyledPeercent>
                        </Box>
                    </Box>
                </Box>

                <Box $top={40}>
                    <AnimationOnScroll
                        animateIn="animate__fadeInUp"
                        animateOnce={true}
                    >
                        <StyledTitle
                            fw={400}
                            fz={16}
                            lh={20}
                            ta="center"
                            $color="#6B6B6B"
                            md={{ fz: 18, lh: 24 }}
                        >
                            Мы рады предложить следующие опции оплаты:
                        </StyledTitle>
                    </AnimationOnScroll>
                </Box>

                <Box $top={32}>
                    <StyledContent
                        $flex
                        fxd="column"
                        fxw="wrap"
                        ai="stretch"
                        md={{ fxd: 'row' }}
                    >
                        <Box
                            $flex
                            fxd="column"
                            $width="100%"
                            md={{ $width: '45%' }}
                            lg={{ $width: '30%' }}
                        >
                            <img src={adv1} alt="" />
                            <StyledBox>
                                <Text
                                    fz={20}
                                    lh="132%"
                                    ta="center"
                                    $color="#000"
                                    fw={500}
                                >
                                    Рекуррентные платежи
                                </Text>
                                <Box $top={8}>
                                    <Text
                                        fz={18}
                                        lh="132%"
                                        ta="center"
                                        $color="#6B6B6B"
                                        fw={400}
                                    >
                                        Повторяющиеся платежи по расписанию,
                                        экономит время клиентов, упрощая процесс
                                        оплаты.
                                    </Text>
                                </Box>
                            </StyledBox>
                        </Box>
                        <Box
                            $flex
                            fxd="column"
                            $width="100%"
                            md={{ $width: '45%' }}
                            lg={{ $width: '30%' }}
                        >
                            <img src={adv2} alt="" />
                            <StyledBox>
                                <Text
                                    fz={20}
                                    lh="132%"
                                    ta="center"
                                    $color="#000"
                                    fw={500}
                                >
                                    Apple Pay и Google Pay
                                </Text>
                                <Box $top={8}>
                                    <Text
                                        fz={18}
                                        lh="132%"
                                        ta="center"
                                        $color="#6B6B6B"
                                        fw={400}
                                    >
                                        Быстрая и безопасная оплата на мобильных
                                        устройствах: однокликовые покупки,
                                        подтверждение отпечатком пальца, кодом
                                        или сканом лица.
                                    </Text>
                                </Box>
                            </StyledBox>
                        </Box>
                        <Box
                            $flex
                            fxd="column"
                            $width="100%"
                            md={{ $width: '45%' }}
                            lg={{ $width: '30%' }}
                        >
                            <img src={adv3} alt="" />
                            <StyledBox>
                                <Text
                                    fz={20}
                                    lh="132%"
                                    ta="center"
                                    $color="#000"
                                    fw={500}
                                >
                                    Сохраненные карты
                                </Text>
                                <Box $top={8}>
                                    <Text
                                        fz={18}
                                        lh="132%"
                                        ta="center"
                                        $color="#6B6B6B"
                                        fw={400}
                                    >
                                        Быстрый доступ к оплате, исключает
                                        необходимость повторного ввода платежной
                                        информации.
                                    </Text>
                                </Box>
                            </StyledBox>
                        </Box>
                        <Box
                            $flex
                            fxd="column"
                            $width="100%"
                            md={{ $width: '45%' }}
                            lg={{ $width: '30%' }}
                        >
                            <img src={adv4} alt="" />
                            <StyledBox>
                                <Text
                                    fz={20}
                                    lh="132%"
                                    ta="center"
                                    $color="#000"
                                    fw={500}
                                >
                                    Инвойсинг
                                </Text>
                                <Box $top={8}>
                                    <Text
                                        fz={18}
                                        lh="132%"
                                        ta="center"
                                        $color="#6B6B6B"
                                        fw={400}
                                    >
                                        Быстрое и эффективное выставление счетов
                                        для клиентов бизнеса
                                    </Text>
                                </Box>
                            </StyledBox>
                        </Box>
                        <Box
                            $flex
                            fxd="column"
                            $width="100%"
                            md={{ $width: '45%' }}
                            lg={{ $width: '30%' }}
                        >
                            <img src={adv5} alt="" />
                            <StyledBox>
                                <Text
                                    fz={20}
                                    lh="132%"
                                    ta="center"
                                    $color="#000"
                                    fw={500}
                                >
                                    Платежные ссылки
                                </Text>
                                <Box $top={8}>
                                    <Text
                                        fz={18}
                                        lh="132%"
                                        ta="center"
                                        $color="#6B6B6B"
                                        fw={400}
                                    >
                                        Специальные ссылки для оплаты, которые
                                        можно размещать на сайтах или отправлять
                                        в электронных сообщениях
                                    </Text>
                                </Box>
                            </StyledBox>
                        </Box>
                        <Box
                            $flex
                            fxd="column"
                            $width="100%"
                            md={{ $width: '45%' }}
                            lg={{ $width: '30%' }}
                        >
                            <img src={adv6} alt="" />
                            <StyledBox>
                                <Text
                                    fz={20}
                                    lh="132%"
                                    ta="center"
                                    $color="#000"
                                    fw={500}
                                >
                                    Подписки
                                </Text>
                                <Box $top={8}>
                                    <Text
                                        fz={18}
                                        lh="132%"
                                        ta="center"
                                        $color="#6B6B6B"
                                        fw={400}
                                    >
                                        Оплата по подписке, что особенно удобно
                                        для сервисов с регулярными платежами.
                                    </Text>
                                </Box>
                            </StyledBox>
                        </Box>
                    </StyledContent>
                </Box>
            </Container>
        </Box>
    );
};

const StyledContent = styled(Box)`
    gap: ${pxRem(48)};
`;

const StyledBox = styled(Box)`
    background: #ffffff;
    box-shadow: 0px 8.05706px 8.71033px rgba(22, 25, 46, 0.1);
    border-radius: 0px 0px 4px 4px;
    padding: ${pxRem(16)} ${pxRem(8)} ${pxRem(40)};
    height: 100%;
`;

const StyledTitle = styled(Text)``;

const StyledPeercent = styled(Text)`
    font-weight: 400;
    font-size: 64px;
    line-height: 130%;
    text-align: center;
    background: linear-gradient(180deg, #b078ff 0%, #350084 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
`;

import React, { FC } from 'react';
import { Box, Collapsible, media, pxRem, Text } from '@sanch941/lib';
import styled from 'styled-components';
import caret from '@assets/images/faq/caret.svg';
import { colors, RichText } from '@lib';
import { useDispatch } from 'react-redux';
import { faqActions } from './model';

const { toggleFaqItem } = faqActions;

export const Card: FC<ComponentProps> = ({ id, title, description, open }) => {
    const dispatch = useDispatch();

    return (
        <StyledContainer open={open}>
            <Collapsible
                open={open}
                $onClick={() =>
                    dispatch(
                        toggleFaqItem({
                            id,
                            open: !open
                        })
                    )
                }
                VisiblePart={<VisiblePart title={title} open={open} />}
                Inner={<Inner description={description} id={id} />}
            />
        </StyledContainer>
    );
};

const VisiblePart = ({ title, open }) => (
    <StyledVisiblePartContainer $flex ai="center" jc="space-between">
        <Text $color="#000000" fw={400} fz={14} sm={{ fz: 18, lh: '132%' }}>
            {title}
        </Text>

        <StyledIconBox $height={32} $width={32} $flex jc="center" ai="center">
            <StyledIcon open={open} src={caret} />
        </StyledIconBox>
    </StyledVisiblePartContainer>
);

const Inner = ({ description, id }) => {
    function createMarkup() {
        return { __html: description };
    }

    return (
        <StyledInner $width="100%">
            <Text
                fz={14}
                lh={16}
                $color="#000"
                fw={400}
                sm={{ fz: 18, lh: 21 }}
                dangerouslySetInnerHTML={createMarkup()}
            />
        </StyledInner>
    );
};

interface ComponentProps {
    id?: number;
    open?: boolean;
    title?: string;
    description?: string;
}

const StyledContainer = styled.div<{ open: boolean }>`
    transition: background-color 0.2s;
    background: #ffffff;
    box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    margin-bottom: ${pxRem(24)};
`;

const StyledVisiblePartContainer = styled(Box)`
    padding: ${pxRem(15)} ${pxRem(13)};

    ${media.md} {
        padding: ${pxRem(20)} ${pxRem(12)};
    }
`;

const StyledIcon = styled.img<{ open: boolean }>`
    transition: transform 0.2s ease-out;
    width: ${pxRem(15)};
    transform: ${({ open }) => (open ? 'rotate(45deg)' : 'rotate(0)')};

    ${media.md} {
        width: ${pxRem(19)};
    }
`;

const StyledIconBox = styled(Box)`
    background: #ffffff;
    box-shadow: 0px 8.05706px 8.71033px rgba(22, 25, 46, 0.1);
    border-radius: 2px;
`;

const StyledInner = styled(Box)`
    padding: 0 ${pxRem(13)} ${pxRem(31)};

    & > p > ul {
        padding-left: ${pxRem(20)};

        & > li {
            list-style-type: disc;
            padding-bottom: ${pxRem(10)};
        }
    }

    ${media.md} {
        padding: 0 ${pxRem(12)} ${pxRem(20)};
    }
`;

import { sendMailAxios } from '@lib/axios';

interface Params {
    name: string;
    phone: string;
    email: string;
    comment: string;
}

interface Res {
    success: boolean;
    message: string;
}

export type SendMailRes = Res;
export type SendMailParams = Params;

export const sendMail = async (params: Params): Promise<any> => {
    const res = await sendMailAxios.post('/send-email', params);
    return res.data;
};
